@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

html, body {
  overflow-x: hidden;  /* Prevent horizontal scroll */
  width: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    background-color: #1e1e1e;
    color: #ffffff;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1e1e1e;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.app-header {
    background-color: transparent;
    padding: 10px 25px;
    padding-left: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app-header .logo {
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease-in-out;
  }
  
.app-header .logo.full {
  width: 150px;
  padding: 10px;
  padding-left: 60px;
  margin-top: 40px;
}

@keyframes growLogo {
  from { transform: scale(0.1); }
  to { transform: scale(1); }
}

.app-header .logo.mini {
  width: 75px;
  padding-left: 30px;
  padding-top: 25px;
  transform: scale(0.8);
  animation: growLogo 0.3s ease-in-out forwards;
}

.header-left, .header-right {
    display: flex;
    align-items: center;
}

.logo {
    cursor: pointer;
    user-select: none;
    width: 150px;
    padding: 10px;
    padding-left: 60px;
    margin-top: 10px;
}  

.new-chat-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.new-chat-btn img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

.new-chat-btn:hover img {
    filter: brightness(0.8);
}

.app-body {
    display: flex;
    height: calc(100vh - 60px); 
    padding-top: 60px; /* Add padding instead of margin to account for fixed header */
    margin-top: 0; /* Remove margin-top */
}

.main-content {
    flex-grow: 1;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    transition: margin-left 0.3s ease-in-out;
    overflow-y: auto;
    margin-left: 0;
  }
  
  .main-content.sidebar-open {
    margin-left: 250px;
  }
  
  .sidebar {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 250px;
    background-color: #232425;
    color: #ffffff;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    transform: translateX(-100%);
    overflow: hidden;
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .sidebar-section {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 20px;
    height: calc(40% - 10px); /* Equal height for both sections */
  }

  .sidebar-section.bibliothek {
    margin-top: 0px;
    height: calc(50% - 60px); /* Equal height for both sections */
  }
  
  .sidebar-section h2 {
    position: sticky;
    top: 0;
    background-color: #232425;
    padding: 10px 0;
    margin: 0 0 0;
    font-size: 20px;
    z-index: 1;
  }
  
  .sidebar-toggle {
    position: absolute;
    top: 10px;
    right: -30px;
    background-color: #2c2c2c;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .chat-history,
  .report-list-container {
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 5px;
    padding-bottom: 15px;
  }
  
  .chat-history::-webkit-scrollbar,
  .report-list-container::-webkit-scrollbar {
    width: 5px;
  }
  
  .chat-history::-webkit-scrollbar-thumb,
  .report-list-container::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 5px;
  }
  
  .chat-history-item,
  .report-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  
  .chat-history-item span,
  .report-item span {
    cursor: pointer;
    flex-grow: 1;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .delete-chat-btn,
  .report-action-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .delete-chat-btn img,
  .report-action-btn img {
    width: 16px;
    height: 16px;
  }
  
  .delete-chat-btn:hover,
  .report-action-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }
  
  .report-actions {
    display: flex;
    gap: 5px;
  }
  
  .chat-history,
  .report-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
.delete-chat-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}
  
.delete-chat-btn img {
    width: 16px;
    height: 16px;
}
  
.delete-chat-btn:hover img {
    filter: invert(0.3); /* Darken the icon on hover */
}

.chat-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  /* Reduce top padding */
  padding-top: 15px;
  /* Remove margin-top, it's contributing to overflow */
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Add max-height to ensure it doesn't expand beyond the viewport */
  max-height: calc(100vh - 80px);
}

p {
    display: block;
    color: white;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.message {
    max-width: 80%;
    margin-bottom: 20px;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 2;
    word-wrap: break-word;
}

.processing-message {
    margin-bottom: 30px;
}

.message.user {
    background-color: #4f4740;
    align-self: center;
}
.message.ai, .message.system {
    background-color: transparent;
    align-self: center;
    border: #1f1f1f;
    border-radius: 10px;
}

.message.ai ol, .message.system ol {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.message.ai li, .message.system li {
    margin-bottom: 8px;
}

/*GREETING MESSAGE*/

.greeting-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 900px;
  margin-top: 150px;
}

.greeting-logo {
  width: 69px;
  margin-bottom: 50px;
}

.greeting-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.greeting-option-left,
.greeting-option-middle,
.greeting-option-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #232425;
  border-radius: 0.75rem;
  padding: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: background-color 0.3s ease;
}

.greeting-option-left:hover {
  background-color: #e1f2f6;
}

.greeting-option-middle:hover {
  background-color: #5ec2d7;
}

.greeting-option-right:hover {
  background-color: #385da8;
}

.greeting-icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-bottom: 0.85rem;
  fill: #8e8ea0;
}

.greeting-icon2 {
  width: 2.2rem;
  height: 2.2rem;
  margin-bottom: 0.5rem;
  fill: #8e8ea0;
}

.greeting-option-left h3,
.greeting-option-right h3,
.greeting-option-middle h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  color: #ffffff;
}

.greeting-option-left p,
.greeting-option-right p,
.greeting-option-middle p {
  font-size: 0.875rem;
  font-weight: 500;
  color: #bfbfbf;
  line-height: 1.4;
}

code {
  font-family: Arial, sans-serif;
}

/*BOTTOM CONTROLS*/
.controls-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  cursor: pointer;
}

.bottom-controls {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 10px;
}

.input-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95vw;
}

.chat-controls,
.document-controls {
  display: flex;
  align-items: center;
  background-color: #40414F;
  border-radius: 10px;
  padding: 5px;
  margin: 0 5px;
}

.document-controls {
  height: 44px;
}

.input-container {
  display: flex;
  align-items: flex-end;
  background-color: #40414F;
  border-radius: 10px;
  width: 40vw;
  max-width: 800px;
}

.auto-resize-textarea {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  padding: 10px;
  line-height: 1.5;
  font-family: inherit;
  resize: none;
  overflow-y: auto;
  height: 44px; 
  min-height: 44px; /* Ensure it doesn't go smaller than this */
  max-height: 400px;
  width: calc(100% - 90px); /* Adjust based on button group width */
  box-sizing: border-box; /* Include padding in the height calculation */
}

.auto-resize-textarea:focus {
  outline: none;
}

.button-group {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
  width: 44px;
  height: 44px;
  background-color: #40414F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-icon {
  width: 20px;
  height: 20px;
  fill: #FFFFFF;
}

.button.orange {
  width: auto;
  min-width: 100px;
  padding: 0 15px;
  background-color: #365da8;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
}

.button.handleSend {
  background-color: rgb(216, 149, 66);
}

.abort-button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  min-width: 100px;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.abort-button .icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.button:hover {
  background-color: #2f2e2e;
}

.button.orange:hover {
  background-color: #88a8e9;
}

.button.handleSend:hover {
  background-color: rgb(255, 216, 143);
}

.abort-button:hover {
  background-color: #ff8787;
}

.media-preview {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.preview-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.media-preview-container,
.document-preview-container {
  min-height: 0;
  transition: all 0.3s ease;
}

.document-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.media-preview button {
  position: absolute;
  top: -5px;
  right: -5px;
  background: rgb(58, 58, 58);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
}

.message-media-preview {
  margin-top: 10px;
}

.processing-message {
  text-align: center;
  margin-top: 20px;
  font-style: italic;
}

.progress-bar-container {
  width: 50vw;
  margin: 20px auto;
  text-align: center;
}

.progress-bar-container .progress {
  height: 24px;
  background-color: #e9ecef;
}

.progress-bar-container .progress-bar {
  background-color: #365da8;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  border-radius: 1px;
}

.progress-bar-container p {
  margin-top: 10px;
  font-size: 14px;
  color: #6c757d;
}

/* Tooltip styles */
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    font-size: 14px;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
}

/* Top tooltip (for buttons at the bottom) */
.tooltip-top .tooltiptext {
    bottom: 125%;
}

.tooltip-top .tooltiptext::after {
    top: 100%;
    border-color: #555 transparent transparent transparent;
}

/* Bottom tooltip (for buttons at the top) */
.tooltip-bottom .tooltiptext {
    top: 125%;
}

.tooltip-bottom .tooltiptext::after {
    bottom: 100%;
    border-color: transparent transparent #555 transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Specific adjustments for the header new chat button */
.header-right .tooltip .tooltiptext {
    right: 0;
    left: auto;
    margin-left: 0;
}

.header-right .tooltip .tooltiptext::after {
    right: 25px;
    left: auto;
}

/* Specific adjustments for the bottom buttons */
.input-container .tooltip .tooltiptext {
    bottom: 60px;
}

.input-container .tooltip .tooltiptext::after {
    bottom: -10px;
    top: auto;
    border-color: #555 transparent transparent transparent;
}

.floating-tooltip {
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: fixed;
    z-index: 1000;
    font-size: 14px;
    pointer-events: none;
    white-space: nowrap;
  }

  .blue-text {
    color: blue;
  }

  a {
    color: #58a6ff;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
  }

 /* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #1e1e1e;
  color: #ffffff;
}

/* Authentication Page Layout */
.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-logo {
  width: 180px;
  margin-bottom: 40px;
}

.auth-container {
  background-color: #232425;
  padding: 20px 20px 30px;
  border-radius: 10px;
  width: 300px;
  position: relative;
  overflow: hidden;
}

/* Form Styles */
.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.auth-form h2 {
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.input-group {
  margin-bottom: 15px;
  width: 80%;
  position: relative;
}

.input-group label {
  display: block;
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 14px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.auth-input,
.input-group input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  background-color: #40414F;
  border: 0.1px solid #565869;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
}

/* Icon Button Styles */
.icon-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 50px;
  height: 30px;
}

/* Submit Button Styles */
.auth-form button[type="submit"] {
  width: 80%;
  padding: 12px;
  background-color: #365da8;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.auth-form button[type="submit"]:hover {
  background-color: #88a8e9;
}

.auth-form button[type="submit"]:disabled {
  background-color: #2c4a8a;
  cursor: not-allowed;
}

/* Auth Toggle and Error Styles */
.auth-toggle {
  margin-top: 20px;
  font-size: 14px;
  color: #a0a0a0;
  text-align: center;
  width: 100%;
}

.auth-toggle button {
  background: none;
  border: none;
  color: #58a6ff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin-left: 5px;
}

.auth-error {
  color: #ff6b6b;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

/* Admin Panel Styles */
.admin-panel {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-header h1 {
  margin: 0;
  font-size: 1.8em;
}

.admin-content {
  display: flex;
  gap: 40px;
}

.create-user-section, 
.user-list-section {
  flex: 1;
}

.user-list-section h2,
.create-user-section h2 {
  font-size: 1em;
}

.createuser {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #2a5528;
  width: 150px;
  height: 40px;
  margin-left: 120px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
  border: none;
  border-radius: 5px;
}

.createuser:hover {
  background-color: #516e4f;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input[type="email"], input[type="password"] {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.delete-btn {
  background-color: #f44336;
  font-size: 12px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #f57f77;
}

.error-message {
  color: #f44336;
}

.success-message {
  color: #4CAF50;
}

/* Logout Button */
/* If you want to add a tooltip to the logout button, similar to the new chat button */
.logout-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  border-radius: 50%;
}

.logout-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-icon {
  width: 24px;
  height: 24px;
  fill: #ffffff; /* Adjust color as needed */
}

.logout-btn .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.logout-btn:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.clickable {
  cursor: pointer;
  transition: transform 0.2s;
}

.clickable:hover {
  transform: scale(1.02);
}

.bottom-right-icon-container {
  position: fixed;
  bottom: 10px;
  right: 20px;
  cursor: pointer;
}

.bottom-right-icon {
  width: 15px;
  height: 15px;
  opacity: 0.6;
}

/* Privacy Page Styles */
.privacy-page {
  min-height: 100vh;
  background-color: #1e1e1e;
  color: white;
  padding: 2rem 1rem;
  position: relative;
}

.privacy-back-button {
  position: absolute;
  top: 2rem;
  left: 2rem;
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.privacy-back-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.privacy-page-content {
  max-width: 4xl;
  margin: 0 auto;
  padding: 2rem;
}

/* Logo section */
.privacy-logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.privacy-logo {
  width: 150px;
  cursor: pointer;
  transition: opacity 0.2s;
  padding: 1rem 2rem;
}

.privacy-logo:hover {
  opacity: 0.8;
}

/* Header styles - all same size */
.privacy-page h1,
.privacy-page h2 {
  font-size: 1rem;  /* Standardized size */
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.privacy-page h3,
.privacy-page h4 {
  font-size: 1rem;  /* Standardized size */
  margin-bottom: 1rem;
  margin-top: 2rem;
}

/* Special style for main title */
.privacy-page .main-title {
  font-size: 1.75rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

/* Date style */
.date {
  color: #999;
  text-align: center;
  margin-bottom: 2rem;
}

/* List styles */
.privacy-page ul,
.privacy-page ol {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.privacy-page li {
  margin-bottom: 0.5rem;
}

/* Links */
.privacy-page a {
  color: #3b82f6;
  text-decoration: none;
}

.privacy-page a:hover {
  text-decoration: underline;
}

/* Spacing between sections */
.privacy-page section {
  margin-bottom: 2rem;
}

/* Text styles */
.privacy-page p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Remove horizontal lines */
.privacy-page hr {
  display: none;
}

.privacy-page-content {
  max-width: 4xl;
  margin: 0 auto;
  padding: 2rem;
}

.impressum-section {
  text-align: center;
  margin-bottom: 4rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.impressum-section h1 {
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}

.impressum-content {
  margin-bottom: 2rem;
}

.impressum-content p {
  margin: 0.25rem 0;
}

.impressum-note {
  font-size: 0.9rem;
  color: #999;
  max-width: 600px;
  margin: 1.5rem auto 0;
}

.uploaded-files-list.compact {
  background: transparent;
  padding: 8px 0;
  margin: 0;
}

.files-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.file-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 16px;
  max-width: 200px;
}

.file-badge .file-icon {
  width: 12px;
  height: 12px;
  color: #aaa;
}

.file-badge .file-name {
  font-size: 12px;
  color: #fff;
}

.remove-badge-btn {
  background: transparent;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.remove-badge-btn:hover {
  opacity: 0.7;
}

.remove-badge-btn .remove-icon {
  width: 12px;
  height: 12px;
  color: #ff9292;
}

.disclaimer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.disclaimer-popup {
  background: #2a2b32;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
  position: relative;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disclaimer-popup h2 {
  margin: 5px 0 10px 0;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}

.disclaimer-content {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 12px;
  width: 100%;
}

.disclaimer-content p {
  margin: 12px 0;
}

.disclaimer-confirm-btn {
  background: #4a4b55;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0 auto;
}

.disclaimer-confirm-btn:hover {
  background: #5a5b65;
}

.disclaimer-confirm-btn:active {
  background: #3a3b45;
}

.permanent-disclaimer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 7px;
  padding-top: 5px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: margin-left 0.3s ease; 
}

.permanent-disclaimer-container.sidebar-open {
  margin-left: 260px; /*Sidebar Width */
  right: 0;
}

.permanent-disclaimer {
  color: #666;
  text-align: center;
  font-size: 12px;
  margin-right: 40px;
  padding: 1px;
}

.bottom-right-icon-container {
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
}

.bottom-right-icon {
  width: 16px;
  height: 16px;
  color: #666;
  cursor: pointer;
  transition: color 0.2s;
}

.bottom-right-icon:hover {
  color: #888;
}

/* Adjust bottom-controls to not overlap with disclaimer */
.bottom-controls {
  margin-bottom: 30px;
}

/*#################### Mobile Optimization ##################### */
@media (max-width: 1500px) {
  .greeting-message {
    margin-top: 100px;
  }

  .greeting-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.45rem;
    fill: #8e8ea0;
  }
  
  .greeting-icon2 {
    width: 1.9rem;
    height: 1.9rem;
    margin-bottom: 0.1rem;
    fill: #8e8ea0;
  }

  .greeting-option-left h3,
  .greeting-option-middle h3,
  .greeting-option-right h3 {
    font-size: 0.9rem;
  }

  .greeting-option-left p,
  .greeting-option-right p,
  .greeting-option-middle p {
    font-size: 0.75rem;
    color: #bfbfbf;
  }
}

@media (max-width: 1100px) {
  .greeting-options {
    width: 80%;
  }

  .greeting-message {
    margin-top: 80px;
  }
}

@media (max-width: 820px) {
  .auto-resize-textarea {
    line-height: 1.6;
}
}

/* Fix input container positioning and sizing */
@media (max-width: 768px) {
  app-header .logo.mini {
    padding-top: 0;
  }

  .logo {
    margin-top: 0;
  }

  .app-header {
    position: absolute; /* Change from fixed to absolute */
    background-color: transparent; /* Add background color to ensure visibility while scrolling */
    padding: 8px 15px;
    height: 45px; /* Reduce header height */
    margin-top: 5px
  }

  .app-body {
    margin-top: 45px; /* Match the header height */
  }

  /* Adjust logo sizes */
  .app-header .logo.full {
    width: 130px;
    padding-left: 30px;
    margin-top: 30px;
  }

  .app-header .logo.mini {
    width: 50px;
    padding-left: 15px;
    padding-top: 10px;
  }

  .input-container {
    width: 100%;
    max-width: none;
    margin: 0 auto;
    padding: 0 10px;
    max-height: 44px;
  }

  .bottom-controls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #1e1e1e;
    padding: 10px 0;
    margin: 0;
    z-index: 1000;
  }

  .chat-controls {
    display: flex;
    align-items: center;
    background-color: #40414F;
    border-radius: 10px;
    padding: 5px;
    margin: 0 5px;
    width: 70%; /* Take 70% of the space */
    box-sizing: border-box;
  }
  
  .document-controls {
    display: flex;
    height: 54px;
    align-items: center;
    background-color: #40414F;
    border-radius: 10px;
    padding: 5px;
    margin: 0 5px;
    width: 33%; /* Take 30% of the space */
    box-sizing: border-box;
    justify-content: flex-end; /* Align content to the right */
  }

  .button.orange {
    min-width: 60px;
    font-size: 8px;
  }

  .button {
    margin: 0;
  }

  .greeting-message {
    width: auto;
    opacity: 0.6;
    margin-top: 40%
  }

  .greeting-option-left,
  .greeting-option-middle,
  .greeting-option-right {
    display: none;
  }

  /* Ensure content is centered and consistent */
  .greeting-option-left h3,
  .greeting-option-middle h3,
  .greeting-option-right h3 {
    margin: 1rem 0;
  }

  .greeting-logo {
    margin-bottom: 30px;
    width: 50px
  }

  .greeting-options {
    flex-direction: column;
  }
  
  .greeting-option {
    width: 100%;
  }

  /* Add padding to chat container to prevent content from being hidden behind input */
  .chat-container {
    padding-bottom: 80px;
  }

  /* Adjust disclaimer position */
  .permanent-disclaimer-container {
    bottom: 70px;
  }

  .permanent-disclaimer {
    display: none;
  }
}

/* Additional small screen optimizations */
@media (max-width: 480px) {
  .input-container {
    padding: 0 5px;
  }

  .auto-resize-textarea {
    font-size: 10px; /* Prevent auto-zoom on iOS */
    padding: 12px;
    line-height: 2.1;
    height: 44px !important;
  }

  .button.handleSend {
    padding: 0 10px;
  }

  .greeting-message {
    margin-top: 70%
  }
}